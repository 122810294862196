import * as firebase from 'firebase/app'

export default class GraphQLClient {
  /**
   * Tenant identifier
   *
   * @private
   * @type {string}
   * @memberof GraphQLClient
   */
  private tenantId: string
  /**
   * Firebase SDK JWT token to authenticate user
   *
   * @private
   * @type {string}
   * @memberof GraphQLClient
   */
  private idToken: string

  private readonly api: firebase.functions.HttpsCallable
  constructor(tenantId: string) {
    this.tenantId = tenantId
    this.api = firebase.functions().httpsCallable('api')
    this.loadUid()
  }

  async executeQuery(query: string) {
    try {
      if(!this.idToken) await this.loadUid()
      const { data } = await this.api({
        text: query,
        tenant: this.tenantId,
        idToken: this.idToken
      })
      return data
    } catch (error) {
      console.log('GraphQL error ', error.message)
      return Promise.reject(error)
    }
  }

  async loadUid() {
    try {
      if (!this.idToken) this.idToken = await firebase.auth().currentUser!.getIdToken()
    } catch (e) {
      console.error(e)
    }
  }
}
