import * as React from 'react';
import { MenuItem, FormControl, Button } from '@karla/karla-react-components';
import { connect } from 'react-redux'
import { startSMSLogin } from '~/redux/actions/smsAuth';
import { DropdownButton } from 'react-bootstrap';
import countryCodes from '../../common/lib/countryCodes';

const mapStateToProps = (state) => {
  return { user: state.auth }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitPhoneNumber: (phone) => {
      dispatch(startSMSLogin(phone))
    }
  }
}

@connect(
  mapStateToProps,
  mapDispatchToProps)
export default class extends React.Component {

  static propTypes = {
    startPasswordlessAuth: React.PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      countryCode: 1,
      countryTitle: '+1 United States',
      phone: '(716) 869-6799'
    };
  }

  handlePhoneInput(event) {
    this.setState({
      phone: event.target.value
    });
  }

  handleCountryCodeChange(code, countryTitle) {
    return () => {
      const countryCode = parseInt(code, 10);
      this.setState({
        countryCode,
        countryTitle: `${code} ${countryTitle}`
      });
    }
  }

  sanitizePhoneNumber(phone) {
    return phone.replace(/\D/g, '')
  }

  submit(event) {
    let phone = this.state.phone;

    if (!phone || phone.length == 0) {
      alert('Please enter a phone number');
      return
    }

    let sanitizedPhone = this.sanitizePhoneNumber(phone);
    let fullPhoneNumber = `+${this.state.countryCode}${sanitizedPhone}`;
    this.props.submitPhoneNumber(fullPhoneNumber);
  }

  render() {
    let codes = countryCodes
    if (window.env.includes('prod')) {
      codes = [Array.from(countryCodes)[0]];
    }
    const codeItems = Array.from(codes).map(({ name: title, dial_code: dialCode, code }) => {
      return <MenuItem key={code} eventKey={code} active onSelect={this.handleCountryCodeChange(dialCode, title)} className="onboarding-text"><span>{dialCode} {title}</span></MenuItem>
    });

    return (
      <div>
        <div className='onboarding-text onboarding-subtitle'>
          We will need to create and verify your account with an SMS code.  Use the Sync City Second Screen to view your SMS messages.
        </div>
        <div className='form-container onboarding-text'>
          <div className='field-container'>
            Country Code<br />
            <DropdownButton title={this.state.countryTitle} id='country-code-dropdown' className='sh-form-control'>
              {codeItems}
            </DropdownButton>
          </div>
          <div className='field-container'>
            HLTH Demo Phone Number<br />
            <FormControl placeholder='(555) 555-5555' value={this.state.phone} onChange={this.handlePhoneInput.bind(this)} className='sh-form-control' />
          </div>
        </div>
        <br />
        <Button id='send-text-btn' className='sh-btn' onClick={this.submit.bind(this)}>Send Text to Second Screen</Button>
      </div>
    );
  }
}
