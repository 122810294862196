import * as debug from './debug'

export async function successes<T>(promises: Promise<T>[]): Promise<T[]> {
  const caught = promises.map((prom) =>
    prom.catch((error) => {
      debug.error('Caught promise error', error)
    })
  )
  const results = await Promise.all(caught)
  const filtered = results.filter((result) => result !== undefined)
  return filtered as T[]
}
