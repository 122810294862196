import * as React from 'react'
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal
} from '@karla/karla-react-components'

import { connect } from 'react-redux'
import Autocomplete from '../../../common/ui/Autocomplete'
import { getUser, User, getTenantUsers } from '../../../db/Users'
import { Organization, getTenantOrg, getTenantOrgs } from '../../../db/Orgs'
import { Role } from '../../../db/Roles'
import { getSpecialistClients } from '../../../db/Telehealth'

function searchItemFromUser(user: User): SearchItem {
  return {
    id: user.id,
    name: user.fullName,
    type: 'user'
  }
}

function searchItemFromOrg(org: Organization): SearchItem {
  return {
    id: org.id,
    name: org.name,
    type: 'org'
  }
}

type AssigneeType = 'user' | 'org'

type FlowSaveState = {
  name: string
  assigneeId: string
  assigneeType: AssigneeType
  isActive: boolean
}

type Props = {
  userId: string
  recipeName: string
  selectedId: string
  selectedType: AssigneeType
  tenantId: string
  role: Role
  onHide: () => void
  onConfirm: (info: FlowSaveState) => void
}

type SearchItem = {
  id: string
  name: string
  type: AssigneeType
}

type State = {
  userSearch: string
  searchCandidates: SearchItem[]
  searchResults: SearchItem[]
  recipeName: string
  selectedId: string
  selectedType: AssigneeType
}

const mapStateToProperties = (state) => {
  return {
    userId: state.auth.uid,
    tenantId: state.auth.selectedRole.tenantId,
    role: state.auth.selectedRole.role
  }
}

class WhenDoSaveModal extends React.Component<Props, State> {
  constructor(properties) {
    super(properties)
    this.state = {
      userSearch: '',
      searchCandidates: [],
      searchResults: [],
      recipeName: properties.recipeName,
      selectedId: properties.selectedId,
      selectedType: properties.selectedType
    }
  }

  componentDidMount() {
    this.loadCurrentAssignee()
    this.loadSearchCandidates()
  }

  loadCurrentAssignee() {
    const { selectedType, selectedId } = this.props
    if (selectedType === 'user') {
      getUser(selectedId).then((user) => {
        this.setState({
          userSearch: user.fullName
        })
      })
    } else if (selectedType === 'org') {
      getTenantOrg(this.props.tenantId, selectedId).then((org) => {
        this.setState({
          userSearch: org.name
        })
      })
    }
  }

  async loadSearchCandidates() {
    const { tenantId, userId, role } = this.props
    let candidates: SearchItem[]
    let clients
    let orgs
    let users

    switch (role) {
      case Role.specialist:
        clients = await getSpecialistClients(tenantId, userId)
        candidates = clients.map((user: User) => searchItemFromUser(user))
        break

      case Role.admin:
      case Role.demo:
        ;[orgs, users] = await Promise.all([
          getTenantOrgs(tenantId),
          getTenantUsers(tenantId)
        ])
        candidates = orgs.map((org: Organization) => searchItemFromOrg(org))
        candidates = candidates.concat(
          users.map((user: User) => searchItemFromUser(user))
        )
        break
      default:
        throw new Error(`Invalid role for assigning WhenDo flow:${role}`)
    }
    this.setState({
      searchCandidates: candidates
    })
  }

  onNameUpdate = (event) => {
    const name = event.target.value
    this.setState({
      recipeName: name
    })
  }

  onItemSelect = (id: string, item: SearchItem) => {
    this.setState({
      selectedId: id,
      selectedType: item.type,
      userSearch: item.name
    })
  }

  onUserSearch = (event: any, value: string) => {
    const results: SearchItem[] = []

    if (value && value.length > 0) {
      const searchTerm = value.toUpperCase()
      for (const item of this.state.searchCandidates) {
        if (item.name?.toUpperCase().indexOf(searchTerm) !== -1) {
          results.push(item)
        }
      }
    }

    this.setState({
      userSearch: value,
      searchResults: results
    })
  }

  saveRecipe(active) {
    const { recipeName, selectedId, selectedType } = this.state
    this.props.onConfirm({
      name: recipeName,
      assigneeId: selectedId,
      assigneeType: selectedType,
      isActive: active
    })
  }

  render() {
    const { recipeName, userSearch, searchResults } = this.state
    return (
      <Modal
        className="sh-modal"
        show
        backdrop
        backdropClassName="modal-shadow-background"
        onHide={this.props.onHide}
      >
        <img
          src="/imgs/icons/x-button.svg"
          className="close-btn hover-pointer"
          onClick={this.props.onHide}
          alt="close"
        />
        <Modal.Body>
          <h1 className="title"> Save Flow </h1>
          <Form>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                type="text"
                value={recipeName}
                onChange={this.onNameUpdate}
              />
              <div>
                <ControlLabel style={{ marginTop: '20px' }}>
                  Assign To
                </ControlLabel>
                <Autocomplete
                  wrapperStyle={{
                    width: '100%'
                  }}
                  inputProps={{
                    name: 'Search for a user...',
                    className: 'form-control'
                  }}
                  ref="userSeachInput"
                  value={userSearch}
                  items={searchResults}
                  getItemValue={(item) => item.id}
                  onSelect={this.onItemSelect}
                  onChange={this.onUserSearch}
                  renderItem={(item: SearchItem) => {
                    let resultType
                    let text: string
                    if (item.type === 'user') {
                      resultType = 'user'
                      text = item.name
                    } else {
                      resultType = 'org'
                      text = `${item.name} - ORGANIZATION`
                    }
                    const id = `${resultType}-${item.id}`
                    return (
                      <div
                        className="admin-dropdown-item"
                        key={id}
                        id={item.id}
                      >
                        {text}
                      </div>
                    )
                  }}
                />
              </div>
            </FormGroup>
          </Form>
          <div>
            <button
              className="sh-btn btn-default"
              onClick={() => this.saveRecipe(true)}
              type="button"
            >
              Save & Activate
            </button>
            <button
              className="sh-btn btn-default"
              type="button"
              onClick={() => this.saveRecipe(false)}
            >
              Save as Inactive
            </button>
          </div>
          <span
            onClick={this.props.onHide}
            className="footnote hover-pointer"
            role="button"
            tabIndex={0}
          >
            {' '}
            I&apos;m not done. Return to editor.{' '}
          </span>
        </Modal.Body>
      </Modal>
    )
  }
}

export default connect(mapStateToProperties)(WhenDoSaveModal)
