
const TYPE_NODE = 1;
const TYPE_TEXT = 3;

class WhenDoXmlParser {


  parseTypesFromXml(whenDoXml) {
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(whenDoXml,"text/xml");
    var typesArray = this.extractWhenDoInfoFromNodes(xmlDoc.documentElement, []);
    var formattedResult = this.formatResults(typesArray);
    return formattedResult;
  }

  formatResults(elementsAndTypes) {
    var whenDoInfo = {
      when: [],
      do: []
    };
    var parsingDoSection = false;
    for (var i=0; i<elementsAndTypes.length; i++) {
        var type = elementsAndTypes[i].type;
        var element = elementsAndTypes[i].element
        if (!parsingDoSection && type.includes('_do')) {
          parsingDoSection = true;
        }
        if (!parsingDoSection) {
          if (element=='block') {
            whenDoInfo.when.push(type + " ");
          } else {
            whenDoInfo.when[whenDoInfo.when.length-1]+= (type + " ");
          }
        } else {
          if (element=='block') {
            whenDoInfo.do.push(type + " ");
          } else {
            whenDoInfo.do[whenDoInfo.do.length-1]+= (type + " ");
          }
        }
    }
    return whenDoInfo;
  }



  extractWhenDoInfoFromNodes(node, types) {
    for (var i=0; i<node.childNodes.length; i++) {
      var thisNode = node.childNodes[i];
      if (thisNode.nodeType==TYPE_NODE) {
        var type = thisNode.getAttribute("type");
        if (type) {
          types.push({type: type, element: thisNode.tagName});
        }
        if (thisNode.childNodes.length>0) {
          this.extractWhenDoInfoFromNodes(thisNode, types);
        }
      } else if (thisNode.nodeType==TYPE_TEXT){
        types.push({type: thisNode.nodeValue, element: thisNode.tagName});
        return types;
      } else {
        return types;
      }

    }
    return types;
  }
}

module.exports.WhenDoXmlParser = WhenDoXmlParser
