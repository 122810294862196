/* eslint-disable */
let config;
window.env = "process.env.APP_ENV"
if ("process.env.APP_ENV" == "dev") {
  config = {
    AUTH0: {
      CLIENT_ID: 'jlhB9P4FfUsgH7HwIBYnm9Qvk72lfEwl',
      AUTH0_DOMAIN: 'mykarla.auth0.com'
    },
    AWS: {
      IDENTITY_POOL_ID: 'us-east-1:c431bbed-ab73-418c-a013-79e1084b40dd',
      REGION: 'us-east-1',
      API_GATEWAY_URL: 'https://karlafunctions-api-dev.karla.ai'
    },
    FIREBASE: {
      ROOT: 'https://karla-dev.firebaseio.com',
      API_KEY: 'AIzaSyDFr97smgR3SvjbIRjp-SZGvcla73k6rL4',
      PROJECT_ID: 'karla-dev',
      APP_ID: '1:766954451416:web:5b346764ae4708801082ad'
    },
    GOOGLE: {
      MAPS_API_KEY: 'AIzaSyBkJbo1HYLlCik0xg-vVslkxEQAQtWHKhs'
    }
  }
} else if ("process.env.APP_ENV" == "staging") {
  config = {
    AUTH0: {
      CLIENT_ID: 'jlhB9P4FfUsgH7HwIBYnm9Qvk72lfEwl',
      AUTH0_DOMAIN: 'mykarla.auth0.com'
    },
    AWS: {
      IDENTITY_POOL_ID: 'us-east-1:f3dfb9f1-ef49-405f-8a66-3908c235f31a',
      REGION: 'us-east-1',
      API_GATEWAY_URL: 'https://karlafunctions-api-staging.karla.ai'
    },
    FIREBASE: {
      ROOT: 'https://karla.firebaseio.com',
      API_KEY: 'AIzaSyClVyuwxHFQk1dnO23EOYgAKHpTJUZepbc',
      PROJECT_ID: 'project-8985240796815252149',
      APP_ID: '1:140080956177:web:9980f4d1ca5aa937d702b0',
      MEASUREMENT_ID: 'G-5E0GP1L2DC'
    },
    GOOGLE: {
      MAPS_API_KEY: 'AIzaSyBkJbo1HYLlCik0xg-vVslkxEQAQtWHKhs'
    }
  }

} else if ("process.env.APP_ENV" == "prod") {
  config = {
    AUTH0: {
      CLIENT_ID: 'jlhB9P4FfUsgH7HwIBYnm9Qvk72lfEwl',
      AUTH0_DOMAIN: 'mykarla.auth0.com'
    },
    AWS: {
      IDENTITY_POOL_ID: 'us-east-1:c850d518-0566-41dc-b7cb-bd24cfce2346',
      REGION: 'us-east-1',
      API_GATEWAY_URL: 'https://karlafunctions-api.karla.ai'
    },
    FIREBASE: {
      ROOT: 'https://karla-prod.firebaseio.com',
      API_KEY: 'AIzaSyDshdHtsvf682wqCN_Wrj3s-hgl8d77CJg',
      PROJECT_ID: 'karla-prod',
      APP_ID: '1:123161363255:web:add5953fc03d5b737650b2',
      MEASUREMENT_ID: 'G-VDHL30JGCS'
    },
    GOOGLE: {
      MAPS_API_KEY: 'AIzaSyBkJbo1HYLlCik0xg-vVslkxEQAQtWHKhs'
    }
  }
} else {
  throw new Error("Please set APP_ENV environment variable to dev, staging, or prod")
}
exports.CONFIG = config
