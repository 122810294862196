import firebase from 'firebase/app'
import { URN } from '@karla/karla-core'
import { User, getUser } from './Users'
import { Keychain } from '../common/lib/Keychain'
import { timestampInSeconds } from './Utils'
import { getTenantOrg, getUserTenantOrgs, Organization } from './Orgs'

const firestore = firebase.firestore()
const auth = firebase.auth()

export enum RecipeAssigneeType {
  org = 'org',
  user = 'user'
}

export type Recipe = {
  id: string
  'urn:server:timestamp': number
  'urn:recipe:author': string
  'urn:recipe:type': 'xml' | 'js'
  'urn:recipe:assigneetype': RecipeAssigneeType
  'urn:recipe:assigneepath': string
  'urn:recipe:assignedto': string
  'urn:recipe:platform:mobile': boolean
  'urn:recipe:trigger'?: object | null
  assigneeName?: string
  authorUser?: {
    'urn:consumer:fullname': string
  }
}

export async function saveRecipe(
  tenantId: string,
  recipe: Recipe
): Promise<void> {
  if (!recipe[URN.SERVER.TIMESTAMP]) {
    recipe[URN.SERVER.TIMESTAMP] = timestampInSeconds()
  }

  signRecipe(recipe)

  const xml = recipe[URN.RECIPE.XML]

  recipe[URN.RECIPE.TYPE] = xml ? 'xml' : 'js'
  recipe[URN.RECIPE.PLATFORMMOBILE] = true

  if (xml) {
    recipe['urn:recipe:trigger'] = buildTriggerInfo(xml)
  }

  const assigneeId = recipe[URN.RECIPE.ASSIGNEDTO]
  const assigneeType =
    recipe[URN.RECIPE.ASSIGNEETYPE] || RecipeAssigneeType.user
  const assigneePath = `${assigneeType}:${assigneeId}`
  recipe[URN.RECIPE.ASSIGNEEPATH] = assigneePath

  const collection = recipesCollection(tenantId)

  const documentAux = recipe.id ? collection.doc(recipe.id) : collection.doc()
  recipe.id = documentAux.id

  try {
    await documentAux.set(recipe, { merge: true })
  } catch (error) {
    console.error(`Save recipe error: ${error}`)
    throw error
  }
}

export async function getAuthoredRecipes(
  tenantId: string,
  userId: string
): Promise<Recipe[]> {
  const query = recipesCollection(tenantId).where(
    URN.RECIPE.AUTHOR,
    '==',
    userId
  )

  try {
    const snapshot = await query.get()
    const getAssignees: Promise<Recipe>[] = snapshot.docs.map((document_) => {
      const recipe = document_.data() as Recipe
      return populateAssigneeName(recipe, tenantId)
    })
    return Promise.all(getAssignees)
  } catch (error) {
    console.error(`Fetch authored recipes error: ${error}`)
    throw error
  }
}

export async function getAssignedRecipes(
  tenantId: string,
  userId: string
): Promise<Recipe[]> {
  const getUserFlows = getAssignedRecipesForType(
    tenantId,
    RecipeAssigneeType.user,
    userId
  )

  const getOrgFlows: Promise<Recipe[][]> = (async () => {
    const orgFlows: { [k: string]: Recipe[] } = {}
    const orgs = await getUserTenantOrgs(userId, tenantId)
    const getFlows = Object.keys(orgs).map((orgId) => {
      return getAssignedRecipesForType(tenantId, RecipeAssigneeType.org, orgId)
        .then((flows) => {
          orgFlows[orgId] = flows
        })
        .catch((error) => {
          console.error(error)
        })
    })
    await Promise.all(getFlows)
    return Object.values(orgFlows)
  })()

  let [userFlows, orgFlows] = await Promise.all([getUserFlows, getOrgFlows])

  for (const flows of orgFlows) {
    userFlows = userFlows.concat(flows)
  }

  await Promise.successes([
    populateAssigneeNames(userFlows, tenantId),
    populateAuthorNames(userFlows)
  ])

  return userFlows
}

export async function toggleRecipeEnabled(
  tenantId: string,
  recipeId: string
): Promise<void> {
  const recipe = await getRecipe(tenantId, recipeId)
  const newValue = !recipe[URN.RECIPE.ENABLED]
  recipe[URN.RECIPE.ENABLED] = newValue

  try {
    await recipesCollection(tenantId).doc(recipeId).set(recipe, { merge: true })
  } catch (error) {
    console.error(`Toggle recipe active error: ${error}`)
    throw error
  }
}

async function populateAssigneeName(
  recipe: Recipe,
  tenantId: string
): Promise<Recipe> {
  const assignee = recipe[URN.RECIPE.ASSIGNEDTO] as string
  switch (recipe[URN.RECIPE.ASSIGNEETYPE]) {
    case RecipeAssigneeType.user:
      return getUser(assignee)
        .then((user) => {
          recipe.assigneeName = user.fullName
          return recipe
        })
        .catch((error) => {
          console.warn(error)
          recipe.assigneeName = 'unknown'
          return recipe
        })
    case RecipeAssigneeType.org:
      return getTenantOrg(tenantId, assignee)
        .then((org) => {
          recipe.assigneeName = org.name
          return recipe
        })
        .catch((error) => {
          console.error(error)
          return recipe
        })
    default:
      return Promise.resolve(recipe)
  }
}

async function populateAuthorNames(recipes: Recipe[]) {
  const authorIdMap = {}
  recipes.forEach((recipe) => {
    const authorId = recipe[URN.RECIPE.AUTHOR]
    if (authorId) {
      authorIdMap[authorId] = true
    }
  })
  const authorIds = Object.keys(authorIdMap)
  const getAuthors = authorIds.map((id) => {
    return getUser(id).catch(() => {
      return { fullName: 'unknown' }
    })
  })
  return Promise.successes(getAuthors).then((authors) => {
    const authorsById = authors.reduce((map, author) => {
      map[author.id] = author
      return map
    }, {})
    return recipes.map((recipe) => {
      const authorId = recipe[URN.RECIPE.AUTHOR]
      if (authorId) {
        const author = authorsById[authorId]
        if (author) {
          // @ts-ignore
          recipe.authorUser = {
            [URN.CONSUMER.FULLNAME]: author.fullName
          }
        }
      }
      return recipe
    })
  })
}

async function populateAssigneeNames(recipes: Recipe[], tenantId: string) {
  const userIds = {}
  const orgIds = {}
  recipes.forEach((recipe) => {
    const assignee = recipe[URN.RECIPE.ASSIGNEDTO] as string
    switch (recipe[URN.RECIPE.ASSIGNEETYPE]) {
      case RecipeAssigneeType.user:
        userIds[assignee] = true
        break
      case RecipeAssigneeType.org:
        orgIds[assignee] = true
        break
      default:
        break
    }
  })
  const users: { [k: string]: User } = {}
  const orgs: { [k: string]: Organization } = {}
  const getUsers = Object.keys(userIds).map((id) => {
    return getUser(id)
      .then((user) => {
        users[id] = user
      })
      .catch(() => {
        return { fullname: 'unknown' }
      })
  })
  const getOrgs = Object.keys(orgIds).map((id) => {
    return getTenantOrg(tenantId, id)
      .then((org) => {
        orgs[id] = org
      })
      .catch(console.error)
  })
  const getAllAssignees = getUsers.concat(getOrgs)
  // @ts-ignore
  return Promise.successes(getAllAssignees).then(() => {
    return recipes.forEach((recipe) => {
      const assignee = recipe[URN.RECIPE.ASSIGNEDTO] as string
      let user: User
      let org: Organization
      switch (recipe[URN.RECIPE.ASSIGNEETYPE]) {
        case RecipeAssigneeType.user:
          user = users[assignee]
          recipe.assigneeName = user.fullName
          break
        case RecipeAssigneeType.org:
          org = orgs[assignee]
          recipe.assigneeName = org.name
          break
        default:
          break
      }
    })
  })
}

export async function deleteRecipe(
  tenantId: string,
  id: string
): Promise<void> {
  try {
    await recipesCollection(tenantId).doc(id).delete()
  } catch (error) {
    console.error(`Delete recipe error: ${error}`)
    throw error
  }
}

async function getAssignedRecipesForType(
  tenantId: string,
  type: RecipeAssigneeType,
  id: string
): Promise<Recipe[]> {
  const assignee = `${type}:${id}`

  const query = recipesCollection(tenantId).where(
    URN.RECIPE.ASSIGNEEPATH,
    '==',
    assignee
  )

  try {
    const snapshot = await query.get()
    return snapshot.docs.map((document_) => document_.data() as Recipe)
  } catch (error) {
    console.error(`Fetch assigned recipes error for : ${error}`)
    throw error
  }
}

export async function getRecipe(tenantId: string, id: string): Promise<Recipe> {
  try {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const retrievedDocument = recipesCollection(tenantId).doc(id)
    const snapshot = await retrievedDocument.get()
    if (!snapshot.exists) {
      throw new Error(`Recipe ${id} not found in tenant ${tenantId}`)
    }
    return snapshot.data() as Recipe
  } catch (error) {
    console.error('getRecipe', error)
    throw error
  }
}

function recipesCollection(
  tenantId: string
): firebase.firestore.CollectionReference {
  return firestore.collection(`tenantRecipes/${tenantId}/recipes`)
}

function signRecipe(recipe: Recipe) {
  const user = auth.currentUser
  if (!user) {
    throw new Error('Must be logged in to save a recipe')
  }
  const authorId = user.uid
  recipe[URN.RECIPE.AUTHOR] = authorId
  const flowSource = recipe[URN.RECIPE.JS]
  const keychain = new Keychain(authorId)
  const signature = keychain.createSignature(flowSource)
  recipe['urn:author:signatureBase64'] = btoa(signature)
}

function buildTriggerInfo(xml): object | null {
  const parser = new DOMParser()
  const xmlDOM = parser.parseFromString(xml, 'text/xml')
  const blocks = xmlDOM.querySelectorAll('block')
  const triggerBlock = getTriggerBlock(blocks)
  if (!triggerBlock) {
    return null
  }
  switch (triggerBlock.getAttribute('type')) {
    case 'location_enter':
    case 'location_exit':
      return buildLocationTriggerInfo(triggerBlock)
    default:
      return null
  }
}

function buildLocationTriggerInfo(block): LocationTriggerInfo {
  const valueElements = block.querySelectorAll('value')
  let radiusElement
  let latElement
  let longElement
  let delayElement

  for (let i = 0, { length } = valueElements; i < length; i++) {
    const valueBlock = valueElements[i]
    const name = valueBlock.getAttribute('name')
    switch (name) {
      case 'RADIUS':
        radiusElement = valueBlock
        break
      case 'LAT':
        latElement = valueBlock
        break
      case 'LONG':
        longElement = valueBlock
        break
      case 'DELAY':
        delayElement = valueBlock
        break
      default:
        break
    }
  }

  const radius = numberValueFromBlock(radiusElement)
  const lat = numberValueFromBlock(latElement)
  const long = numberValueFromBlock(longElement)
  const delay = numberValueFromBlock(delayElement)

  return {
    type: 'location',
    event: block.getAttribute('type'),
    args: {
      radius,
      lat,
      long,
      delay
    }
  }
}

type LocationTriggerInfo = {
  type: 'location'
  event: string
  args: {
    radius: number | null
    lat: number | null
    long: number | null
    delay: number | null
  }
}

function getTriggerBlock(blocks): any {
  if (!blocks || blocks.length === 0) {
    return null
  }
  for (const block of blocks) {
    if (block.getAttribute('type') === 'control_when') {
      // eslint-disable-next-line no-continue
      continue
    } else {
      return block
    }
  }
  return null
}

function numberValueFromBlock(block): number | null {
  const value = Number.parseFloat(valueFromBlock(block))
  return Number.isNaN(value) ? null : value
}

function valueFromBlock(block): string {
  return block.querySelectorAll('field')[0].textContent
}
