import * as React from 'react'
import { MultiBarGraphCard } from './MultiBarGraphCard'
import { getRandomColor } from '../../../common/lib/randomColor'
import GraphQlClient from '../../../db/GraphQL'

const emptyGraphData: GraphData = { labels: [], datasets: [] }

type State = {
  graphData: GraphData | null
  loading: boolean
  queryRetry: boolean
}

type Props = {
  userId: string
  tenantId: string
}

export class TotalContentAssignmentStats extends React.Component<Props, State> {
  readonly state: State

  readonly props: Props

  constructor(properties) {
    super(properties)

    this.state = {
      graphData: emptyGraphData,
      loading: true,
      queryRetry: false
    }

    this.queryData = this.queryData.bind(this)
  }

  componentDidMount() {
    this.queryData()
  }

  shouldComponentUpdate(nextProperties: Props, nextState: State) {
    return !!(
      this.props.userId !== nextProperties.userId ||
      (nextState.graphData &&
        this.state.graphData &&
        this.state.graphData.labels.length !==
          nextState.graphData.labels.length)
    )
  }

  componentWillUpdate(nextProperties: Props) {
    if (this.props.userId !== nextProperties.userId) {
      this.queryData()
    }
  }

  async getAPIData(tenant: string): Promise<GraphData> {
    const api = new GraphQlClient(tenant)
    return api
      .executeQuery(
        `{totalSurveys {totalAssignedSurveys, totalCompletedSurveys}}`
      )
      .then((httpdata) => transformContentToDataSet(httpdata))
      .catch((error: Error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }

  async queryData() {
    let graphData: GraphData
    try {
      graphData = await this.getAPIData(this.props.tenantId)
      return this.setState({ graphData, loading: false })
    } catch (error) {
      console.error(error)
      if (this.state.queryRetry) {
        this.setState({ queryRetry: true })
        setTimeout(this.queryData, 2000)
      }
      return this.setState({ graphData: emptyGraphData, loading: false })
    }
  }

  render() {
    const { graphData } = this.state
    if (!graphData || this.state.loading) {
      return <MultiBarGraphCard title="Loading..." data={graphData} />
    }

    const GraphCard =
      graphData.labels.length === 0 ? (
        <MultiBarGraphCard title="No results found" />
      ) : (
        <MultiBarGraphCard title="Content Assignment Totals" data={graphData} />
      )

    return <div style={{ paddingBottom: '10px' }}>{GraphCard}</div>
  }
}

type TotalContentAssignmentStatsData = {
  totalAssignedSurveys: number
  totalCompletedSurveys: number
}

type DataSet = {
  label: string
  fillColor?: string
  strokeColor?: string
  highlightFill?: string
  highlightStroke?: string
  data: number[]
}

export type GraphData = {
  datasets: [DataSet] | []
  labels: string[]
}

async function transformContentToDataSet(data: {
  totalSurveys: TotalContentAssignmentStatsData
}): Promise<GraphData> {
  const color1 = getRandomColor()
  const color1f = getRandomColor()
  const graphData: GraphData = {
    labels: ['Total assigned surveys', 'Total completed surveys'],
    datasets: [
      {
        label: 'Surveys',
        fillColor: color1,
        strokeColor: color1,
        highlightFill: color1f,
        highlightStroke: color1f,
        data: [
          data.totalSurveys.totalAssignedSurveys,
          data.totalSurveys.totalCompletedSurveys
        ]
      }
    ]
  }

  return graphData
}
