import * as React from 'react'
import CloseButton from '~/common/ui/CloseButton'
import { ControlLabel, FormControl, Modal } from 'react-bootstrap'
import { Form, FormGroup } from '@karla/karla-react-components'

export const FlowTypes = {
  js: 'js',
  xml: 'xml'
}

export class NewFlowModal extends React.Component {
  
  static propTypes = {
    onHide: React.PropTypes.func.isRequired,
    onSave: React.PropTypes.func.isRequired
  };

  constructor(props) {
    super(props)
  }

  visualFlowClicked() {
    this.props.onSave(FlowTypes.xml)
  }

  javaScriptFlowClicked() {
    this.props.onSave(FlowTypes.js)
  }
  
  render() {
    const centerStyle = {"text-align": "center"}
    return (
      <Modal className="sh-modal"
            show={true}   
            backdrop={true} 
            backdropClassName="modal-shadow-background"
            onHide={this.props.onHide}>
        <div style={centerStyle}>
          <h1 className="title">Choose Format</h1>
          <div style={centerStyle}>
            <button className="sh-btn btn-default" onClick={this.visualFlowClicked.bind(this)}>Visual Editor</button>
          </div>
          <div style={centerStyle}>
            <button className="sh-btn btn-default" onClick={this.javaScriptFlowClicked.bind(this)}>JavaScript</button>
          </div>
          <div style={centerStyle}>
            <button className="sh-btn btn-default" onClick={this.props.onHide}>Cancel</button>
          </div>
        </div>
      </Modal>
    )
  }
}