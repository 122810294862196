import * as React from 'react';
import { DashElement } from './../DashGrid'
import { Line } from 'react-chartjs';

const defaultOptions = {
  maintainAspectRatio: false,
  scaleShowHorizontalLines: false,
  datasetFill : false
}

export class MultiLineGraphCard extends React.Component {
  
  render() {
    const { title, data, options = defaultOptions } = this.props
    const chart = data ? <Line data={data} options={options} width="500" height="170" redraw/> : undefined
    return (
      <DashElement size={6}>
        <div className="title">{title}</div>
        {chart}
      </DashElement>
    )
  }
}
