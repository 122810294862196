import * as React from 'react';
import { ButtonGroup, Dropdown, DropdownButton, MenuItem } from '@karla/karla-react-components';
import { FormGroup, ControlLabel, FormControl, OverlayTrigger, Popover } from 'react-bootstrap'
import DatePicker from 'react-bootstrap-date-picker'
import moment from 'moment'

const currentDateISO = () => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0,0,0).toISOString()
}

const initialState = {
  date: currentDateISO(),
  startTime: "",
  startMeridian: "PM",
  endTime: "",
  endMeridian: "PM",
  timeOptions: generateTimeOptions(),
  errorMessage: null
}

export class CreateSessionPanel extends React.Component {

  static propTypes = {
    createSession: React.PropTypes.func.isRequired
  };

  constructor(props) {
    super(props)
    this.state = initialState
  }

  submitForm() {
    const baseDate = this.getBaseDate()
    const startDate = this.getDate(baseDate, this.state.startTime, this.state.startMeridian)
    const endDate = this.getDate(baseDate, this.state.endTime, this.state.endMeridian)
    this.props.createSession(startDate, endDate)
      .then(() => this.setState(initialState))
      .catch(err => {
        this.setState({
          errorMessage: err.toString()
        })
      })
  }

  getBaseDate() {
    return moment(this.state.date)
  }

  getDate(baseDate, time, meridian) {
    let adjustedDate = baseDate.clone()
    const timeComponents = time.split(':')
    const hour = parseInt(timeComponents[0], 10)
    const minutes = parseInt(timeComponents[1], 10)
    let adjustedHour = hour
    if (meridian == "PM" && hour < 12) {
      adjustedHour = hour + 12
    }
    adjustedDate.set({
      hour: adjustedHour,
      minute: minutes,
      second: 0
    })
    const unix = adjustedDate.unix()
    return unix
  }

  onDateChange(dateString) {
    this.setState({
      date: dateString
    }, () => this.validateForm())
  }

  onStartTimeChange(time) {
    this.setState({
      startTime: time
    }, () => this.validateForm())
  }

  onStartMeridianChange(meridian) {
     this.setState({
      startMeridian: meridian
    }, () => this.validateForm())
  }

  onEndTimeChange(time) {
     this.setState({
      endTime: time
    }, () => this.validateForm())
  }

  onEndMeridianChange(meridian) {
    this.setState({
      endMeridian: meridian
    }, () => this.validateForm())
  }

  renderHourDropdown(value, onChange) {
    return (
       <Dropdown id="hour-dropdown" >
         <Dropdown.Toggle className="hour-dropdown" noCaret>
        <span className="float-left">{value}</span>
      </Dropdown.Toggle>
        <Dropdown.Menu onSelect={onChange} className="scrollable-menu">
          {this.state.timeOptions.map(option => {
            return <MenuItem  key={option} eventKey={option}>{option}</MenuItem>
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  renderMeridianDropdown(value, onChange) {
    const meridianOptions = ["AM", "PM"]
    return (
        <Dropdown id="meridian-dropdown">
             <Dropdown.Toggle className="meridian-dropdown" noCaret>
          <span className="float-left">{value}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu onSelect={onChange}>
          {meridianOptions.map(meridian => {
            return <MenuItem key={meridian} eventKey={meridian}>{meridian}</MenuItem>
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  validateForm() {
    const { startMeridian, endMeridian, startTime, endTime } = this.state
    const baseDate = this.getBaseDate()
    const startDate = this.getDate(baseDate, startTime, startMeridian)
    const endDate = this.getDate(baseDate, endTime, endMeridian)
    const now = new Date().getTime() / 1000
    if (endDate < startDate) {
      this.setState({
        errorMessage: "Session end time must be after session start time."
      })
    } else if (startDate < now) {
      this.setState({
        errorMessage: "Session must be scheduled in the future."
      })
    } else {
      this.setState({
        errorMessage: null
      })
    }
  }

  renderValidation() {
    if (this.state.errorMessage) {
      return <div className="validation-error">{this.state.errorMessage}</div>
    } else {
      return null
    }
  }

  render() {
    const { startTime, startMeridian, endTime, endMeridian, date } = this.state
    let submitEnabled = true
    if (startTime.length == 0 || endTime.length == 0 || this.state.errorMessage) {
      submitEnabled = false
    }
    return (
      <div className="container-body create-session-panel">
        <div className='dashboard-panel-header'>
          Add a Session
        </div>
        <FormGroup>
          <ControlLabel>Date</ControlLabel>
          <div className='session-date-picker'>
            <DatePicker value={date} onChange={this.onDateChange.bind(this)} />
          </div>
          <ControlLabel>Start Time</ControlLabel>
          <ButtonGroup>
          {this.renderHourDropdown(startTime, this.onStartTimeChange.bind(this))}
          {this.renderMeridianDropdown(startMeridian, this.onStartMeridianChange.bind(this))}
          </ButtonGroup>
          <ControlLabel>End Time</ControlLabel>
          <ButtonGroup>
          {this.renderHourDropdown(endTime, this.onEndTimeChange.bind(this))}
          {this.renderMeridianDropdown(endMeridian, this.onEndMeridianChange.bind(this))}
          </ButtonGroup>
          {this.renderValidation()}
        </FormGroup>
        <button className="rounded-form-button" disabled={!submitEnabled} onClick={this.submitForm.bind(this)}>Add Session</button>
      </div >
    )
  }
}

function generateTimeOptions() {
  let times = []
  for (let h = 0; h < 12; h++) {
    const hour = h === 0 ? 12 : h
    for (let m = 0; m < 4; m++) {
      const minute = m == 0 ? `00` : `${m * 15}`
      const time = `${hour}:${minute}`
      times.push(time)
    }
  }
  return times
}