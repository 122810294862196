import firebase from 'firebase/app';
import { fromCollectionRef, fromDocRef } from 'rxfire/firestore'
import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { User, getUser } from './Users';
import { successes } from '../util/promise_utils';
import { slugify } from '../util/string_utils'

const app = firebase.app()
const firestore = firebase.firestore()

export type Organization = {
  id: string
  name: string
}

export type TenantOrganization = {
  id: string
  name: string
  tenantId: string
  createdDate: number
}

export type OrgMemberships = {[id: string]: boolean}

export async function getTenantOrg(tenantId: string, orgId: string): Promise<TenantOrganization> {
  const doc = tenantOrgsCollection(tenantId).doc(orgId)
  const snapshot = await doc.get()
  if (snapshot.exists) {
    return snapshot.data() as TenantOrganization
  } else {
    throw new Error(`Org ${orgId} not found in tenant ${tenantId}`)
  }
}

export async function createTenantOrg(tenantId: string, org: {name: string}): Promise<void> {
  const orgsCollection = tenantOrgsCollection(tenantId)
  const id = slugify(org.name)
  const orgRecord: TenantOrganization = {
    id,
    name: org.name,
    createdDate: Math.floor(new Date().getTime() / 1000),
    tenantId: tenantId
  }
  return orgsCollection
    .doc(id)
    .set(orgRecord)
}

export async function updateTenantOrg(tenantId: string, org: {id: string, name: string}): Promise<void> {
  const orgsCollection = tenantOrgsCollection(tenantId)
  return orgsCollection
    .doc(org.id)
    .set(org, {merge: true})
}

export async function deleteTenantOrg(tenantId: string, orgId: string): Promise<void> {

  async function removeUserFromTenantOrg(userId: string, tenantId: string, orgId: string): Promise<void> {
    const userOrgsDoc = userTenantOrgsDoc(userId, tenantId)
    return userOrgsDoc.set({
      [orgId]: firebase.firestore.FieldValue.delete()
    }, { merge: true })
  }

  const updates: Promise<void>[] = []
  const userIds = await getTenantOrgUserIds(tenantId, orgId)
  for (const userId of Object.keys(userIds)) {
    updates.push(
      removeUserFromTenantOrg(userId, tenantId, orgId)
    )
  }
  const orgUsersDoc = tenantOrgUsersDoc(tenantId, orgId)
  updates.push(orgUsersDoc.delete())
  const orgDoc = tenantOrgsCollection(tenantId).doc(orgId)
  updates.push(orgDoc.delete())
  await Promise.all(updates)
}

export function observeTenantOrgs(tenantId: string): Observable<TenantOrganization[]> {
  const orgsCollection = tenantOrgsCollection(tenantId)
  return fromCollectionRef(orgsCollection)
    .pipe(map(snapshot => {
      const docs = snapshot.docs
      return docs.map(doc => {
        const value = doc.data()
        return value as TenantOrganization
      })
    }))
}

export async function getTenantOrgs(tenantId: string): Promise<TenantOrganization[]> {
  const orgsCollection = tenantOrgsCollection(tenantId)
  const snapshot = await orgsCollection.get()
  return snapshot.docs.map(doc => {
    const value = doc.data()
    return value as TenantOrganization
  })
}

export async function getTenantOrgUserIds(tenantId: string, orgId: string): Promise<OrgMemberships> {
  const snapshot = await tenantOrgUsersDoc(tenantId, orgId).get()
  return snapshot.data() || {}
}

export async function getTenantOrgsById(tenantId: string): Promise<{[id: string]: TenantOrganization}> {
  const orgsCollection = tenantOrgsCollection(tenantId)
  const snapshot = await orgsCollection.get()
  return snapshot.docs.reduce((map, doc) => {
    const org = doc.data() as TenantOrganization
    map[doc.id] = org
    return map
  }, {})
}

export async function getUserTenantOrgs(userId: string, tenantId: string): Promise<OrgMemberships> {
  const snapshot = await userTenantOrgsDoc(userId, tenantId).get()
  if (snapshot.exists) {
    return snapshot.data() as OrgMemberships
  } else {
    return { }
  }
}

export async function updateUserTenantOrgs(userId: string, tenantId: string, orgs: OrgMemberships): Promise<void> {
  const existingOrgs = await getUserTenantOrgs(userId, tenantId)
  const existingIds = Object.keys(existingOrgs)
  var updates: Promise<void>[] = []
  for (const orgId of existingIds) {
    if (!orgs[orgId]) {
      const orgDoc = tenantOrgUsersDoc(tenantId, orgId)
      const update = orgDoc.set({
        [userId]: firebase.firestore.FieldValue.delete()
      }, { merge: true })
      updates.push(update)
    }
  }
  for (const orgId of Object.keys(orgs)) {
    if (existingOrgs[orgId]) {
      continue
    }
    const orgUsersDoc = tenantOrgUsersDoc(tenantId, orgId)
    updates.push(orgUsersDoc.set({
      [userId]: true
    }, { merge: true }))
  }
  const userOrgsDoc = userTenantOrgsDoc(userId, tenantId)
  updates.push(userOrgsDoc.set(orgs))
  await Promise.all(updates)
    .catch(err => {
      console.error("Error updating orgs ", err)
      throw err
    })
}

export async function getTenantOrgUserCount(tenantId: string, orgId: string): Promise<number> {
  const usersDoc = await tenantOrgUsersDoc(tenantId, orgId).get()
  if (usersDoc.exists) {
    const userIds = usersDoc.data() as OrgMemberships
    return Object.keys(userIds).length
  } else {
    return 0
  }
}

export function observeTenantOrgUsers(tenantId: string, orgId: string): Observable<User[]> {
  const usersDoc = tenantOrgUsersDoc(tenantId, orgId)
  return fromDocRef(usersDoc)
    .pipe(
      map((snapshot) => {
        const members: OrgMemberships = snapshot.data() || {}
        return Object.keys(members)
      }),
      flatMap((userIds) => {
        const getAllUsers = userIds.map(getUser)
        return successes(getAllUsers)
      })
    )
}

function tenantOrgsCollection(tenantId: string): firebase.firestore.CollectionReference {
  return firestore.collection(`tenantOrgs/${tenantId}/orgs`)
}

function userTenantOrgsDoc(userId: string, tenantId: string): firebase.firestore.DocumentReference {
  return firestore.doc(`userOrgs/${tenantId}/users/${userId}`)
}

function tenantOrgUsersDoc(tenantId: string, orgId: string): firebase.firestore.DocumentReference {
  return firestore.doc(`orgUsers/${tenantId}/orgs/${orgId}`)
}