import * as React from 'react'
import { MultiLineGraphCard } from './MultiLineGraphCard'
import { getRandomColor } from '../../../common/lib/randomColor'
import GraphQlClient from '../../../db/GraphQL'
import { getSecondsTimeStamp as convertToTimestamp } from '../../../common/lib/date_utils'
import { shortenToWords } from '../../../util/string_utils'

type Query = {
  unit: string
  startDate: number
  endDate: number
  description: string
}
const emptyGraphData: GraphData = { labels: [], datasets: [] }

type State = {
  graphData: GraphData | null
  loading: boolean
  queryRetry: boolean
}

type Props = {
  userId: string
  tenantId: string
  role: string
  query: Query
}

export class SurveyScoresStats extends React.Component<Props, State> {
  constructor(properties) {
    super(properties)

    this.state = {
      graphData: emptyGraphData,
      loading: false,
      queryRetry: false
    }
    this.queryData = this.queryData.bind(this)
    this.getAPIData = this.getAPIData.bind(this)
  }

  componentWillReceiveProps() {
    if (!this.state.loading) {
      this.setState({
        loading: true,
        graphData: emptyGraphData,
        queryRetry: true
      })
      setTimeout(this.queryData, 500)
    }
  }

  async getAPIData(
    uid: string,
    tenant: string,
    query: Query
  ): Promise<GraphData> {
    const api = new GraphQlClient(tenant)
    const now = new Date()
    const {
      startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      ),
      endDate = now
    } = query
    const rootQuery =
      this.props.role === 'admin'
        ? `surveyScoresPerTenant(tenantId: "${tenant}" minTimestamp:${convertToTimestamp(
            startDate
          )} maxTimeStamp: ${convertToTimestamp(endDate)})`
        : `surveyScoresPerTenant(specialistId: "${uid}" tenantId: "${tenant}" minTimestamp:${convertToTimestamp(
            startDate
          )} maxTimeStamp: ${convertToTimestamp(endDate)})`
    return api
      .executeQuery(
        `{${rootQuery} {timestamp, surveyID, surveyDesc, score, specialist {fullName}} }`
      )
      .then((httpdata) => {
        return transformContentToDataSet(httpdata.surveyScoresPerTenant)
      })
      .catch((error: Error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }

  async queryData() {
    try {
      const graphData = await this.getAPIData(
        this.props.userId,
        this.props.tenantId,
        this.props.query
      )
      this.setState({ loading: false, graphData })
    } catch (error) {
      console.error(error)
      if (!this.state.queryRetry) {
        this.setState({ queryRetry: true })
        setTimeout(this.queryData, 2000)
      }
    }
  }

  render() {
    const { graphData } = this.state
    let title = `Survey scores averages `
    if (this.props.query.description) {
      const description = this.props.query.description.split('')
      description[0] = description[0].toLowerCase()
      title += description.join('')
    }
    if (this.state.loading || !graphData) {
      return <MultiLineGraphCard title="Loading..." data={graphData} />
    }
    return graphData.labels?.length === 0 ? (
      <MultiLineGraphCard title="No results found" data={graphData} />
    ) : (
      <div style={{ paddingBottom: '10px' }}>
        <MultiLineGraphCard
          title={title}
          data={graphData}
          options={{
            tooltipTemplate: `<%if (datasetLabel){%><%=datasetLabel%>: <%}%><%= value %>`,
            datasetFill: false
          }}
        />
      </div>
    )
  }
}

type SurveyScore = {
  timestamp: number
  surveyID: string
  surveyDesc: string
  score: number
  tenantId: string
  specialist: {
    firstName?: string
    lastName?: string
    fullName?: string
  }
}

type DataSet = {
  label: string
  fillColor?: string
  strokeColor?: string
  pointColor?: string
  pointStrokeColor?: string
  pointHighlightFill?: string
  pointHighlightStroke?: string
  data: (number | undefined | null)[]
}

export type GraphData = {
  datasets: DataSet[]
  labels: string[]
}
type Datum = {
  [date: string]: {
    score: number
    date: Date
    surveyID: string
    surveyDesc: string
    specialistName: string
  }[]
}
async function transformContentToDataSet(
  surveyScoresPerTenant: SurveyScore[]
): Promise<GraphData> {
  const dataByDate = surveyScoresPerTenant.reduce<Datum>((accum, datum) => {
    if (!datum?.surveyID || Number.isNaN(Number(datum.score))) {
      return accum
    }
    const {
      surveyID,
      surveyDesc,
      score,
      timestamp,
      specialist: { fullName = 'Unknown' }
    } = datum
    const date = new Date(timestamp * 1000)

    const dateString = date.toLocaleDateString()
    if (!accum[dateString]) {
      accum[dateString] = []
    }
    accum[dateString].push({
      score,
      surveyID,
      surveyDesc,
      date,
      specialistName: fullName
    })
    return accum
  }, {})

  const result: GraphData = Object.keys(dataByDate).reduce<GraphData>(
    (graphData: GraphData, dateString: string, index) => {
      graphData.labels.push(dateString)
      const surveyData = dataByDate[dateString]
      surveyData.forEach((surveyDatum) => {
        const label = shortenToWords(surveyDatum.surveyDesc, 15)
        let dataset = graphData.datasets.find(
          // Change the label to aggregate data
          (dset) => dset.label === label
        )
        const { score } = surveyDatum

        if (dataset) {
          const previousScore = dataset.data[index] || 0
          dataset.data[index] = Number.parseFloat(
            (previousScore ? +((score + previousScore) / 2) : score).toFixed(2)
          )
        } else {
          const color = getRandomColor()
          const color2 = getRandomColor()
          dataset = {
            label,
            strokeColor: color,
            fillColor: color,
            pointStrokeColor: color,
            pointColor: color,
            pointHighlightFill: color2,
            pointHighlightStroke: color2,
            data: []
          }
          dataset.data[index] = Number.parseInt(score.toFixed(2)) || 0
          graphData.datasets.push(dataset)
        }
        for (let i = 0; i < dataset.data.length; i++) {
          if (!dataset.data[i]) {
            dataset.data[i] = 0
          }
        }
      })
      return graphData
    },
    { labels: [], datasets: [] }
  )

  return result
}
