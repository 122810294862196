import * as React from 'react'
import { Row, Col } from '@karla/karla-react-components'
import { connect } from 'react-redux'
import { DashGrid, DashGridRow } from './DashGrid'
import { DateRangePicker } from './DateRangePicker'
import { ContentAssignmentStats } from './cards/ContentAssignmentStats'
import { viewLastWeek, viewLastMonth } from '../../redux/actions/dashboard'
import { SurveyScoresStats } from './cards/SurveyScoresStats'
import { TotalContentAssignmentStats } from './cards/TotalContentAssignmentStats'
import { shallowEqual } from '../../common/lib/object_helpers'

type Query = {
  unit: string
  startDate: number
  endDate: number
  description: string
}

type DispatcherFn = {
  type: string
  query: Query
}

type Props = {
  userId: string
  tenantId: string
  role: string
  query: Query
  selectLastWeek: () => DispatcherFn
  selectLastMonth: () => DispatcherFn
}
type State = {
  dateRangeString: string
  startDate: string
  unit: string
}

function mapStateToProperties(state) {
  const { query } = state.dashboard
  const { auth } = state
  return {
    userId: auth.uid,
    tenantId: auth.selectedRole.tenantId,
    role: auth.selectedRole.role,
    query
  }
}

function mapDispatchToProperties(dispatch: (fn: DispatcherFn) => any) {
  return {
    selectLastWeek: () => dispatch(viewLastWeek()),
    selectLastMonth: () => dispatch(viewLastMonth())
  }
}

class DashboardContainer extends React.Component<Props, State> {
  componentDidMount() {
    if (!this.props.query) {
      this.props.selectLastWeek()
    }
  }

  shouldComponentUpdate(nextProperties: Props) {
    return (
      !shallowEqual(this.props, nextProperties) ||
      !shallowEqual(this.props.query, nextProperties.query)
    )
  }

  render() {
    const { userId } = this.props
    const { tenantId } = this.props
    const query = this.props.query || {}
    const now = new Date()
    const {
      description = '',
      startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      ).getTime()
    } = query
    return (
      <div id="body">
        <DashGrid>
          <Row>
            <Col sm={6}>
              <h1>{`${description}`}</h1>
            </Col>
            <Col sm={6}>
              <DateRangePicker />
            </Col>
          </Row>
          <DashGridRow>
            <SurveyScoresStats
              userId={userId}
              tenantId={tenantId}
              role={this.props.role}
              query={{ ...query, startDate }}
            />

            <ContentAssignmentStats
              userId={userId}
              tenantId={tenantId}
              role={this.props.role}
            />
          </DashGridRow>
          <DashGridRow>
            <TotalContentAssignmentStats
              userId={userId}
              tenantId={tenantId}
              role={this.props.role}
            />
          </DashGridRow>
        </DashGrid>
      </div>
    )
  }
}

export default connect(
  mapStateToProperties,
  mapDispatchToProperties
)(DashboardContainer)
