import * as React from 'react';
import { DashElement } from './../DashGrid'
const BarGraph = require("react-chartjs").Bar;

const defaultOptions = {
  scales: {
    yAxes: [{
      barPercentage: 0.5,
      ticks: {
        beginAtZero:true
      }
    }]
  },
  responsive: true,
  maintainAspectRatio: false
}

export class MultiBarGraphCard extends React.Component {
  
  render() {
    const { title, data, options = defaultOptions } = this.props
    return (
      <DashElement size={6}>
        <div className="title">{title}</div>
        <BarGraph data={data} options={options} redraw/>
      </DashElement>
    )
  }
}
