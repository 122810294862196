import * as React from 'react';
import { IndexRoute, Route, withRouter } from 'react-router';

import { MainContainer } from '@karla/karla-react-components';

import { UserAuthWrapper } from 'redux-auth-wrapper'
import { Keychain } from '~/common/lib/Keychain'

/* Common Components */

import Sidebar from './common/sidebar'
import Header from './common/header'
import Footer from './common/footer'

/* Pages */

// Auth / Onboarding
import Login from './routes/auth/login'
import LoginOrRegister from './routes/auth/index'
import Register from './routes/auth/register'
import SMSAuth from './routes/auth/smsAuth'
import SMSConfirm from './routes/auth/smsConfirm'
import OnboardingContainer from './routes/auth/containers/onboarding'
import ConsumerLandingContainer from './routes/auth/consumerLandingContainer'

// Dashboard
import DashboardContainer from './routes/dashboard/dashboardContainer'

// Organizations
import OrgContainer from './routes/orgs/OrgContainer'
import OrgDetailContainer from './routes/orgs/details/OrgDetailContainer'

// Users
import UserDetailContainer from './routes/users/userDetailContainer'

// Telehealth
import { TelehealthClientDashboard } from './routes/telehealth/TelehealthClientDashboard'
import RouteWrapper from './routes/telehealth/RouteProtector'

// When Do
import { WhenDoContainer } from './routes/whendo/containers/WhenDoContainer'
import WhenDoLibraryContainer from './routes/whendo/containers/whendoLibraryContainer'
import InspectRecipeContainer from './routes/whendo/recipe_detail/InspectRecipeContainer'
import InviteContainer from './routes/orgs/invites/InviteContainer';
import { Role } from './db/Roles';

@withRouter
class App extends React.Component {

  render() {
    return (
      <MainContainer {...this.props}>
        <Sidebar {...this.props} />
        <Header />
        {this.props.children}
        <Footer />
      </MainContainer>
    )
  }
}

/**
 * Includes Sidebar, Header and Footer.
 */

const Authenticated = UserAuthWrapper({
  authSelector: (state) => {
    const uid = state.auth ? state.auth.uid : null
    if (!uid) {
      return null
    }
    const keychain = new Keychain(uid)
    const hasKey = keychain.hasCachedKeyPair()
    return hasKey ? state.auth : null
  },
  wrapperDisplayName: 'Authenticated',
  failureRedirectPath: '/authenticate',
})

const AdminRoute = UserAuthWrapper({
  authSelector: (state) => {
    const { uid, selectedRole } = state.auth
    if (!uid || !selectedRole) {
      return null
    }
    if (selectedRole.role !== 'admin' && selectedRole.role !== 'demo') {
      return null
    }
    const keychain = new Keychain(uid)
    const hasKey = keychain.hasCachedKeyPair()
    return hasKey ? state.auth : null
  },
  wrapperDisplayName: 'AdminRoute',
  failureRedirectPath: '/',
})

export default (
  <Route >
    <Route path='/' component={Authenticated(App)}>
      <IndexRoute component={RouteWrapper(OrgContainer, Role.admin)} />
      <Route path='dashboard' component={RouteWrapper(DashboardContainer, Role.admin)} />
      <Route path='organizations' component={RouteWrapper(OrgContainer, Role.admin)} />
      <Route path='organizations/create' component={RouteWrapper(OrgDetailContainer, Role.admin)} />
      <Route path='organizations/:orgId' component={RouteWrapper(OrgDetailContainer, Role.admin)} />
      <Route path='invites' component={RouteWrapper(InviteContainer, Role.admin)} />
      <Route path='users/:userId' component={AdminRoute(UserDetailContainer)} />
      <Route path='/whendo' component={WhenDoContainer} />
      <Route path='/whendo/library' component={WhenDoLibraryContainer} />
      <Route path='/whendo/flows/:recipeId' component={InspectRecipeContainer} />
      <Route path='clients/:clientId' component={RouteWrapper(TelehealthClientDashboard, Role.specialist)} />
    </Route>
    <Route path='/authenticate' component={OnboardingContainer}>
      <IndexRoute component={LoginOrRegister} />
      <Route path='/login' component={Login} />
      <Route path='/register' component={Register} />
      <Route path='/sms' component={SMSAuth} />
      <Route path='/sms-confirm' component={SMSConfirm} />
      <Route path='/come-back-soon' component={ConsumerLandingContainer} />
    </Route>
  </Route>
)
