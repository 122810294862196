
import { AuthManager } from '~/common/lib/AuthManager'
import { CONFIG } from '~/config'
const AWS = window.AWS

function newClient() {
  let accessKeyId = AWS.config.credentials.accessKeyId
  let secretAccessKey = AWS.config.credentials.secretAccessKey
  let sessionToken = AWS.config.credentials.sessionToken
  let region = AWS.config.region

  let apigClient = apigClientFactory.newClient({
    accessKey: accessKeyId,
    secretKey: secretAccessKey,
    sessionToken: sessionToken,
    region: region,
    baseURL: CONFIG.AWS.API_GATEWAY_URL
  })
  return apigClient
}

export default class APIGatewayClient {

  constructor() {
    this.client = newClient()
    this.authManager = new AuthManager()
  }

  sendInvite({ phoneNumber, firstName, lastName, tenantId, roles, override, specialistId, organizations }) {
    const params = {}
    const body = {
      phoneNumber,
      firstName,
      lastName,
      tenantId,
      roles,
      specialistId,
      organizations,
      override
    }
    const req = () => {
      return this.client.invitesPost(params, body) 
    }
    return this.refreshAuthIfNeeded().then(() => {
      return this.sendRequestWithAuthRetry(req).catch((err) => {
        const errMessage = prettyErrorMessage(err)
        throw new Error(errMessage)
      })
    })
  }

  refreshAuthIfNeeded() {
    let refresh = null
    if (this.authManager.isAWSAuthTokenExpired()) {
      console.log("AWS credentials expired, refreshing...")
      refresh = this.authManager.refreshAWSSession().then(() => {
        this.client = newClient()
      })
    } else {
      refresh = Promise.resolve()
    }
    return refresh
  }

  sendRequestWithAuthRetry(requestFn, retryCount = 2) {
    return requestFn().catch((err) => {
      if (this.authManager.isAWSAuthTokenExpired() && retryCount > 0) {
        console.log("Request failed with expired credentials, refreshing")
        return this.authManager.refreshAWSSession().then(() => {
          this.client = newClient()
          return this.sendRequestWithAuthRetry(requestFn, retryCount - 1)
        })
      } else {
        throw err
      }
    })
  }
}

function prettyErrorMessage(err) {
  var message
  if (err && err.data && err.data.message) {
    message = '' + err.data.message
  } else {
    message = 'Unknown error'
  }
  if (message.toLowerCase().includes('phonenumber')) {
    return 'Invalid phone number.'
  }
  return message
}