import * as React from 'react'
import moment from 'moment'
import { OverlayTrigger, Tooltip } from '@karla/karla-react-components'
import { connect } from 'react-redux'
import { Recipe } from '../../../db/Recipes'

type Props = {
  userId: string
  tenantId: string
  role: string
  id: string
  title: string
  assignedUser: string
  createdTimestamp: number
  isActive: boolean
  isSelected?: boolean
  onCopy: (any) => any
  onEdit: (any) => void
  onInspect?: (recipe: Recipe) => void
  onToggleActive: (any) => any
  onDelete: () => void
  handleSelection?: (any) => any
  recipeDescription: {
    when: never[]
    do: never[]
  }
  recipe: Recipe
}

function mapStateToProperties(
  state
): {
  userId: string
  tenantId: string
  role: string
} & Props {
  const { auth } = state
  return {
    userId: auth.uid,
    tenantId: auth.selectedRole.tenantId,
    role: auth.selectedRole.role,
    ...state
  }
}

export class RecipeListRowDumb extends React.PureComponent<Props> {
  renderWhenDoSection(when, index) {
    return (
      <span key={index}>
        {when}
        <br />
      </span>
    )
  }

  render() {
    let playAction = 'Start'
    if (this.props.isActive) {
      playAction = 'Pause'
    }

    const {
      title,
      assignedUser,
      createdTimestamp,
      recipeDescription
    } = this.props

    const disableInspect =
      !this.props.recipe['urn:recipe:trigger'] ||
      !(this.props.onInspect && typeof this.props.onInspect === 'function')
    const createdDate = moment
      .unix(createdTimestamp)
      .format('MM/DD/YYYY hh:mm a')

    const inspectButton = (
      <a
        onClick={() =>
          !disableInspect && this.props.onInspect
            ? this.props.onInspect(this.props.recipe)
            : null
        }
        tabIndex={0}
        aria-hidden="true"
        role="button"
        className={disableInspect ? 'whendo-disabled' : ''}
      >
        {' '}
        Inspect /
      </a>
    )

    const isOrgRecipe = this.props.recipe['urn:recipe:assigneetype'] === 'org'
    const isAdmin = this.props.role === 'admin'

    const disabledDelete = !isAdmin && isOrgRecipe

    const deleteButtonTooltip = (
      <Tooltip data-html="true" id="tooltip" className="whenDoTooltip">
        You cannot delete an Org assigned When-Do
      </Tooltip>
    )

    const deleteButton = (
      <a
        role="button"
        onClick={() => (!disabledDelete ? this.props.onDelete() : null)}
        tabIndex={0}
        aria-hidden="true"
        className={disabledDelete ? 'whendo-disabled delete' : 'delete'}
      >
        Delete
      </a>
    )
    const deleteButtonWithPermissions = (
      <OverlayTrigger
        placement="right"
        overlay={deleteButtonTooltip}
        show={disabledDelete}
      >
        {deleteButton}
      </OverlayTrigger>
    )

    const tooltip = (
      <Tooltip data-html="true" id="tooltip" className="whenDoTooltip">
        <span className="bolder">WHEN: </span>
        {recipeDescription.when.map((element, index) =>
          this.renderWhenDoSection(element, index)
        )}
        <span className="bolder">DO: </span>
        {recipeDescription.do.map((recipe, index) =>
          this.renderWhenDoSection(recipe, index)
        )}
      </Tooltip>
    )

    return (
      <tr
        key={this.props.id}
        className="whendoRow"
        onClick={this.props.handleSelection}
      >
        <OverlayTrigger placement="right" overlay={tooltip}>
          <td>{title}</td>
        </OverlayTrigger>
        <td>{assignedUser}</td>
        <td>{createdDate}</td>
        <td>
          <a role="button" tabIndex={0} onClick={this.props.onEdit}>
            Edit
          </a>{' '}
          /{' '}
          <a role="button" tabIndex={0} onClick={this.props.onCopy}>
            Copy
          </a>{' '}
          /{inspectButton}{' '}
          <a role="button" tabIndex={0} onClick={this.props.onToggleActive}>
            {playAction}
          </a>{' '}
          / {disabledDelete ? deleteButtonWithPermissions : deleteButton}
        </td>
      </tr>
    )
  }
}

export const RecipeListRow = connect(mapStateToProperties)(RecipeListRowDumb)
