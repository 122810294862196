import * as React from 'react'
import { MultiBarGraphCard } from './MultiBarGraphCard'
import { getRandomColor } from '../../../common/lib/randomColor'
import GraphQlClient from '../../../db/GraphQL'
import { shortenToWords } from '../../../util/string_utils'

const emptyGraphData: GraphData = { labels: [], datasets: [] }

type Props = {
  userId: string
  tenantId: string
  role: string
}

type State = {
  graphData?: GraphData | null
  loading: boolean
  queryRetry: boolean
}

export class ContentAssignmentStats extends React.Component<Props, State> {
  constructor(properties) {
    super(properties)

    this.state = {
      graphData: emptyGraphData,
      loading: true,
      queryRetry: false
    }

    this.queryData = this.queryData.bind(this)
  }

  componentDidMount() {
    this.queryData()
  }

  shouldComponentUpdate(nextProperties: Props, nextState: State) {
    return !!(
      this.props.userId !== nextProperties.userId ||
      (nextState.graphData &&
        this.state.graphData &&
        this.state.graphData.labels.length !==
          nextState.graphData.labels.length)
    )
  }

  componentWillUpdate(nextProperties: Props) {
    if (this.props.userId !== nextProperties.userId) {
      this.queryData()
    }
  }

  async getAPIData(uid: string, tenant: string): Promise<GraphData | null> {
    const api = new GraphQlClient(tenant)
    return api
      .executeQuery(
        `{specialistStats {specialist {fullName}, contentAssignmentStats {assignedSurveys completedSurveys}}}`
      )
      .then((httpdata) => transformContentToDataSet(httpdata))
      .catch((error: Error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }

  async queryData() {
    let graphData: GraphData | null
    try {
      graphData = await this.getAPIData(this.props.userId, this.props.tenantId)
      return this.setState({ graphData, loading: false })
    } catch (error) {
      console.error(error)
      if (this.state.queryRetry) {
        this.setState({ queryRetry: true })
        setTimeout(this.queryData, 2000)
      }
      return this.setState({ graphData: emptyGraphData, loading: false })
    }
  }

  render() {
    const { graphData } = this.state
    if (!graphData || this.state.loading) {
      return <MultiBarGraphCard title="Loading..." data={graphData} />
    }

    const GraphCard =
      graphData.labels.length === 0 ? (
        <MultiBarGraphCard title="No results found" />
      ) : (
        <MultiBarGraphCard
          title="Content Assignments by Specialist"
          data={graphData}
        />
      )

    return <div style={{ paddingBottom: '10px' }}>{GraphCard}</div>
  }
}

type ContentAssignmentStatsData = {
  specialist: {
    firstName?: string
    lastName?: string
    fullName?: string
  }
  contentAssignmentStats: {
    assignedSurveys: number
    completedSurveys: number
  }
}

type DataSet = {
  label: string
  fillColor?: string
  strokeColor?: string
  highlightFill?: string
  highlightStroke?: string
  data: number[]
}

export type GraphData = {
  datasets: [DataSet, DataSet] | []
  labels: string[]
}

async function transformContentToDataSet(data: {
  specialistStats: ContentAssignmentStatsData[]
}): Promise<GraphData | null> {
  if (!data) {
    console.error(new Error('No data provided'))
    return null
  }
  return data.specialistStats.reduce<GraphData>(
    (graphData: GraphData, data) => {
      let {
        specialist: { fullName = 'Unknown' },
        contentAssignmentStats: { assignedSurveys, completedSurveys }
      } = data
      if (!fullName) {
        fullName = 'unknown'
      }
      graphData.labels.push(shortenToWords(fullName, 15))
      if (graphData.datasets.length === 0) {
        const color1 = getRandomColor()
        const color1f = getRandomColor()
        const color2 = getRandomColor()
        const color2f = getRandomColor()
        graphData.datasets = [
          {
            label: 'Assigned Surveys',
            fillColor: color1,
            strokeColor: color1,
            highlightFill: color1f,
            highlightStroke: color1f,
            data: [assignedSurveys]
          },
          {
            label: 'Completed Surveys',
            fillColor: color2,
            strokeColor: color2,
            highlightFill: color2f,
            highlightStroke: color2f,
            data: [completedSurveys]
          }
        ]
      } else {
        graphData.datasets[0].data.push(assignedSurveys)
        graphData.datasets[1].data.push(completedSurveys)
      }
      return graphData
    },
    { labels: [], datasets: [] }
  )
}
