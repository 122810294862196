import Datastore from '~/db/Datastore'

export class Keychain {

  constructor(userId) {
    this.datastore = new Datastore()
    this.userId = userId
  }

  hasCachedKeyPair() {
    const cached = this.getCachedKeyPair()
    return cached && cached.privateKey && cached.publicKey && !cached.isTemporary
  }

  hasValidKeyPair() {
    const cached = this.getCachedKeyPair()
    console.log('cached', cached)
    if (!cached || !cached.privateKey || !cached.publicKey) {
      return Promise.resolve(false)
    }
    return this.getPKIPublicKeyPEM(this.userId).then(publicKey => {
      publicKey = publicKey.replace(/(\r\n|\n|\r)/gm, "")
      console.log('publicKey', JSON.stringify(publicKey), publicKey.length)
      console.log('cachedKey', JSON.stringify(cached.publicKey), cached.publicKey.length)
      if (!publicKey) {
        console.log('Missing public key')
        return false
      }
      console.log(cached.publicKey == publicKey ? 'Valid public key' : 'incorrect public key')
      console.log('valid', this.validatePEMKeyPair(cached))
      return this.validatePEMKeyPair(cached)
    })
  }

  // Key Transfer

  sendKeyRequest() {
    return this.generateTempKeyPair().then(tempKeyPair => {
      this.cacheKeyPair(tempKeyPair)
      const pin = this.getRandomPin()
      const body = {
        pin: pin,
        tempKey: tempKeyPair.publicKey,
        created: Date.timestamp()
      }
      const ref = this.getKeyRequestsRef().push()
      return this.datastore.updateRef(ref, body).then(() => {
        body.id = ref.key
        return body
      })
    })
  }

  listenForKeyResponse(requestId) {
    return new Promise((resolve, reject) => {
      this.responseRef = this.getKeyResponsesRef(requestId)
      this.responseRef.on('value', snapshot => {
        const response = snapshot.val()
        if (!response) {
          return
        }
        resolve(response)
      })
    })
      .then(response => {
        return this.handleKeyResponse(response)
      })
      .then((success) => {
        if (success) {
          return this.cleanUpKeyRequest(requestId)
        }
      })
      .catch(err => {
        console.log('key response error: ', err)
        this.stopKeyResponseListener()
      })
  }

  stopKeyResponseListener() {
    if (this.responseRef) {
      this.responseRef.off('value')
      this.responseRef = null
    }
  }

  handleKeyResponse(response) {

    var privateKey = this.decryptBase64(response.encPrivateKey)
    if (!privateKey || !response.publicKey) {
      return
    }
    privateKey = formatPrivateKeyPEM(privateKey)
    if (!privateKey) {
      return false
    }
    const publicKey = formatPublicKeyPEM(response.publicKey)
    const newKeyPair = {
      publicKey,
      privateKey
    }
    if (this.validatePEMKeyPair(newKeyPair)) {
      this.cacheKeyPair(newKeyPair)
      return true
    }
  }

  encryptObject(object, receiverId) {
    return this.getPublicKey(receiverId).then(publicKey => {
      const jsonBytes = jsonByteStringFromObject(object)
      const encBytes = this.encryptByteString(jsonBytes, publicKey)
      const encBase64 = btoa(encBytes)
      const signature = this.createSignature(encBase64)
      const signatureBase64 = btoa(signature)
      return {
        encData: encBase64,
        signature: signatureBase64
      }
    })
  }

  decryptObject(object, fromUserId) {
    if (!object.encData) {
      throw new Error('invalid encrypted record: ', JSON.stringify(object, null, 2))
    }
    const encBase64 = object.encData
    const signature = object.signature
    return this.verifySignature(encBase64, signature, fromUserId).then(isValid => {
      if (!isValid) {
        console.log('could not verify object signature')
        return null
      }
      const jsonString = this.decryptBase64(encBase64)
      return JSON.parse(jsonString)
    })
  }

  encryptByteString(byteString, publicKey) {

    // generate AES key and encrypt the message
    const aesKey = forge.random.getBytesSync(32)
    const iv = forge.random.getBytesSync(16)
    const cipher = forge.cipher.createCipher('AES-CBC', aesKey)

    cipher.start({ iv: iv })
    cipher.update(forge.util.createBuffer(byteString))
    cipher.finish()
    const aesEncryptedMessage = cipher.output.bytes()

    // encrypt AES key and iv with RSA key
    const headerBytes = aesKey + iv
    const rsaEncryptedHeader = publicKey.encrypt(headerBytes, 'RSA-OAEP')

    // append them all together
    const appended = rsaEncryptedHeader + aesEncryptedMessage
    return appended
  }

  decryptBase64(cipherText) {

    try {
      const privateKey = this.getPrivateKey()

      const encryptedByteChars = atob(cipherText)
      const aesHeaderByteChars = encryptedByteChars.substring(0, 256)
      const aesEncryptedMessage = encryptedByteChars.substring(256)

      // decrypt AES key and iv header using RSA key
      const decryptedHeader = privateKey.decrypt(aesHeaderByteChars, 'RSA-OAEP', {})
      const aesKeyByteString = decryptedHeader.substring(0, 32)
      const ivByteString = decryptedHeader.substring(32, 48)

      // decrypt message with AES key
      var decipher = forge.cipher.createDecipher('AES-CBC', aesKeyByteString)
      decipher.start({ iv: ivByteString })
      decipher.update(forge.util.createBuffer(aesEncryptedMessage))
      decipher.finish()

      return decipher.output.toString()

    } catch (err) {
      console.log('decryption error')
    }
    return null
  }

  createSignature(data) {
    const privateKey = this.getPrivateKey()
    const md = forge.md.sha256.create()
    md.update(data, 'utf8')
    const sig = privateKey.sign(md)
    return sig
  }

  verifySignature(data, signature, signerId) {
    return this.getPublicKey(signerId).then(publicKey => {
      return true
      //FIXME: actually verify the signature, seems to be an encoding issue
      // const dataBinary = atob(data)
      // const signatureBinary = atob(signature)
      // return publicKey.verify(dataBinary, signatureBinary)
    })
  }

  validatePEMKeyPair({ publicKey, privateKey }) {
    try {
      forge.pki.publicKeyFromPem(publicKey)
      forge.pki.privateKeyFromPem(privateKey)
      return true
    } catch (err) {
      console.log('invalid key error: ', err)
      return false
    }
  }

  cancelKeyRequest(requestId) {
    this.clearLocalKeys()
    return this.cleanUpKeyRequest(requestId)
  }

  cleanUpKeyRequest(requestId) {
    this.stopKeyResponseListener()
    const reqRef = this.getKeyRequestsRef(requestId)
    const deleteReq = this.datastore.deleteRef(reqRef)
    const resRef = this.getKeyResponsesRef(requestId)
    const deleteRes = this.datastore.deleteRef(resRef)
    return Promise.all([deleteReq, deleteRes])
  }

  getKeyRequestsRef(reqId) {
    const basePath = 'keyRequests/' + this.userId
    const path = basePath + (reqId ? `/${reqId}` : '')
    return this.datastore.getRef(path)
  }

  getKeyResponsesRef(resId) {
    const basePath = 'keyResponses/' + this.userId
    const path = basePath + (resId ? `/${resId}` : '')
    return this.datastore.getRef(path)
  }

  getRandomPin() {
    return this.getRandomNumericString(6)
  }

  getRandomNumericString(length) {
    let valueStr = ""
    while (valueStr.length < length) {
      valueStr += this.getRandomValues()
    }
    if (valueStr.length > length) {
      return valueStr.substring(0, length)
    }
    return valueStr
  }

  getRandomValues() {
    if (window.crypto) {
      var buffer = new Uint32Array(1)
      window.crypto.getRandomValues(buffer)
      return "" + buffer[0]
    } else {
      let valueStr = "" + Math.random()
      const decimalIndex = valueStr.indexOf('.', 0)
      return valueStr.substring(decimalIndex + 1, valueStr.length - decimalIndex)
    }
  }

  getPublicKey(userId) {
    var getKey
    userId = userId || this.userId
    if (userId == this.userId) {
      const localKeys = this.getCachedKeyPair()
      if (localKeys && localKeys.publicKey) {
        getKey = Promise.resolve(localKeys.publicKey)
      } else {
        getKey = Promise.reject(new Error('encryption keys not found'))
      }
    } else {
      getKey = this.getPKIPublicKeyPEM(userId)
    }
    return getKey.then(publicKeyPEM => {
      return forge.pki.publicKeyFromPem(publicKeyPEM)
    })
  }

  getPrivateKey() {
    const localKeys = this.getCachedKeyPair()
    if (!localKeys || !localKeys.privateKey) {
      new Error('encryption keys not found')
    }
    return forge.pki.privateKeyFromPem(localKeys.privateKey)
  }

  generateTempKeyPair() {
    const pki = forge.pki
    const rsa = pki.rsa
    return new Promise(function (resolve, reject) {
      rsa.generateKeyPair({ bits: 2048, workers: 2 }, function (err, keypair) {
        if (err) {
          reject(err)
          return
        }
        const publicKey = pki.publicKeyToPem(keypair.publicKey)
        const privateKey = pki.privateKeyToPem(keypair.privateKey)
        resolve({
          publicKey,
          privateKey,
          isTemporary: true
        })
      })
    })
  }

  getPKIPublicKeyPEM(userId) {
    if (!userId) throw new Error('must provide userId for getPKIPublicKeyPEM')
    const path = 'pki/' + userId + '/publicKey'
    return this.datastore.getValueAtPath(path)
  }

  cacheKeyPair({ publicKey, privateKey, isTemporary }) {
    const record = {
      publicKey,
      privateKey,
      isTemporary: isTemporary || false
    }
    const raw = JSON.stringify(record)
    const storageKey = this.getKeyPairStorageKey()
    localStorage.setItem(storageKey, raw)
  }

  getCachedKeyPair() {
    const storageKey = this.getKeyPairStorageKey()
    if (storageKey === 'us-east-1:a199567d-f241-4535-aec8-e90273fc4bb2-private') {
      console.log('returning embedded keys')
      return {
        "publicKey": "-----BEGIN PUBLIC KEY-----MIIBCgKCAQEAxrTtHnrkMPqtBNeoBJUl9Qkbmb9A5YpzrZZorunyydu9Gv0yxvvzlLIM25fDihmi0wTlPdQkmjEh6rrFj1q25GK07/1sqSLYeq0ZsdCjBVGz/Bm2vcowCJNhZTPt+NB6nYELFAIpbd/D6gdgZPDE+ytDMIzINtkAQD2JsJIgOMeVM4AQsN3BnkKmzIYZmpAB2GPoqeg7kl9NPy/t7j+4QvSAn0tQUfkkJMp+2rAL/nbvGCtzix/ahQPyuyYUku4wjVCD42/TvWdeNOzTJZlWVMp9NaVFklql8d5oX78p90ztuFJcPLeeoU84HiC+xYXn/4GV6iMz25Uu5uXfgQILQwIDAQAB-----END PUBLIC KEY-----".replace(/(\r\n|\n|\r)/gm, ""),
        "privateKey": "-----BEGIN PRIVATE KEY-----MIIEowIBAAKCAQEAxrTtHnrkMPqtBNeoBJUl9Qkbmb9A5YpzrZZorunyydu9Gv0yxvvzlLIM25fDihmi0wTlPdQkmjEh6rrFj1q25GK07/1sqSLYeq0ZsdCjBVGz/Bm2vcowCJNhZTPt+NB6nYELFAIpbd/D6gdgZPDE+ytDMIzINtkAQD2JsJIgOMeVM4AQsN3BnkKmzIYZmpAB2GPoqeg7kl9NPy/t7j+4QvSAn0tQUfkkJMp+2rAL/nbvGCtzix/ahQPyuyYUku4wjVCD42/TvWdeNOzTJZlWVMp9NaVFklql8d5oX78p90ztuFJcPLeeoU84HiC+xYXn/4GV6iMz25Uu5uXfgQILQwIDAQABAoIBACSGt5vFvVKfkdR1DAajoBGV9gPiGzzWoYMERgP5L08Z3H4skvvu7oyj2ic3k5M8OSkPGlCuQXgyleh/IrfCViJPXrFK5YGFzUNntHRyRLVCmpI569ShaQj6honC7Piy4gc35GBuMU1oN6w6ZObJN3xwLG6tQlSKuTgV18ykX9Xvzt3TXyTTRe4HZtLxnlyYvpjDNsikf+jZaQqTSOl64qddzAOCjAPIOdzhgIEfpcEE5k9HkWKJKMLKWpRMyCz2SSxRDvx15+UdH0TdHeFdB8o96RmPCzhTehaINV4qyL14rpkiBfdFsif0g5hTQV76xqnIZL9Lkpra+Z/Hv0+xH8kCgYEA97HkBXIY9OTAACOA6a/yramT4ogwqDfo71YaIBovpJ+iu/ztJyCvCdTXPIFgBqurgfRoTGdymG1UM7ejB88yuqfMPZCuOBA9hQvAWreSa5hwZS9D58CCh0mjoupoIClK8xyQ1BgbXvHMBUrnAu7uerq+EPYyTdA3tvouVFvE9DcCgYEAzV6KtFltw7VLuP3iPtnIP/wHY950WYuxKw8t/14LRXbP5pBruoPXS8fAJP07jz5hcfA7wR2LkglXGqbKP7TeXx/4hw+xeMMX3NDvYb7oJL4kr2Ei8Dmdaw0ThkYZCE/QzgIlo5ITqk4GJrdI6atzX3yWKQ2E80C7qPQwaYS6M1UCgYAuttYSUNp0FVBAtnFxoUVbi0fgyV6j2yeLO+nhdA7YNWMzoRf48SkR6ZpORvgdSfMmpzmkbz4lkTj4KAIPTvx6R3s1gTvaRvXTScwVT9nFgRJxguCDrMLxUOoTp7HLnv4Lq6+E0KcY2PFpbh7zZqyDaQS/ni8Ojd50G+rw7f3PMQKBgDZYwX3ueISk8m/3B6s4OKzuBgqgsMuNkwnhD3wXqAXu5h+OsmN+RjgwmSFwvhUvHdxoBXgaWHgB7sIHx1fKhlpH/J3JQl7BygvOYrrMKWSnyM98diF6LbVJlZQf8rnnXUtW+BzsKDYWRbxzHmFv1K09LAObJ0a0J7UTQch6rketAoGBANec580nfxksrEoUDg2vWXnY58bmz6wbbsVO+edvlavFZUQGUxOH091wz6m3fsJSiWHMa1WkxZZaA3PMxgckWB99c/POkxrIwVHhSFsNoWw80JfXAiVWH6FoMBFHZJzjXX0FDYTgCVBHhT5fsWUgEK1ifBtP5i5HJvMDfdlodAJL-----END PRIVATE KEY-----".replace(/(\r\n|\n|\r)/gm, ""),
        "isTemporary": false
      }
    }
    const raw = localStorage.getItem(storageKey)
    return JSON.parse(raw)
  }

  getKeyPairStorageKey() {
    return this.userId + '-private'
  }

  clearLocalKeys() {
    const storageKey = this.getKeyPairStorageKey()
    localStorage.removeItem(storageKey)
  }
}

function formatPublicKeyPEM(rawKey) {
  return "-----BEGIN PUBLIC KEY-----" + rawKey.replace(/(\r\n|\n|\r)/gm, "") + "-----END PUBLIC KEY-----"
}

function formatPrivateKeyPEM(rawKey) {
  return "-----BEGIN PRIVATE KEY-----" + rawKey.replace(/(\r\n|\n|\r)/gm, "") + "-----END PRIVATE KEY-----"
}

function jsonByteStringFromObject(object) {
  const jsonString = JSON.stringify(object)
  return unescape(encodeURIComponent(jsonString))
}
