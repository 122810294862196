import * as React from 'react';
import moment from 'moment'
import { connect } from 'react-redux'
import { viewLastWeek, 
         viewLastMonth, 
         viewLastQuarter, 
         viewLastYear } from '~/redux/actions/dashboard'
import classNames from 'classnames'

const mapStateToProps = (state) => {
  const query = state.dashboard.query || {}
  return {
    selectedRange: query.unit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectedLastWeek: () => dispatch(viewLastWeek()),
    selectedLastMonth: () => dispatch(viewLastMonth()),
    selectedLastQuarter: () => dispatch(viewLastQuarter()),
    selectedLastYear: () => dispatch(viewLastYear())
  }
}

@connect(
  mapStateToProps,
  mapDispatchToProps)
export class DateRangePicker extends React.Component {
  
  static propTypes = {
    updatedDateRange: React.PropTypes.func,
    selectedRange: React.PropTypes.oneOf(['week', 'month', 'quarter', 'year'])
  };
  
  classNamesForItem(item) {
    return classNames({
      underline: item == this.props.selectedRange
    })
  }
  
  render() {
    const selection = this.props.selectedRange
    return (
      <div className='text-right'>
        <a onClick={this.props.selectedLastWeek} className={this.classNamesForItem("week")}>Last Week</a> &nbsp; | &nbsp;
        <a onClick={this.props.selectedLastMonth} className={this.classNamesForItem("month")}>Last Month</a> &nbsp; | &nbsp;
        <a onClick={this.props.selectedLastQuarter} className={this.classNamesForItem("quarter")}>Last Quarter</a> &nbsp; | &nbsp; 
        <a onClick={this.props.selectedLastYear} className={this.classNamesForItem("year")}>Last Year</a> 
      </div>
    )
  }
}
